import { makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { useEffect, useMemo, useState, VFC } from "react";
import { useUserContext } from "../context/UserContext";
import { reclaim } from "../reclaim-api";
import { ReclaimSku } from "../reclaim-api/Users";
import { pluralize } from "../utils/strings";
import { Banner } from "./Banner";
import { Link } from "./Link";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
  }),
  {
    classNamePrefix: "TrialExpirationBanner",
  }
);

export type TrialExpirationBannerJSSClassKey = keyof ReturnType<typeof useStyles>;

export type TrialExpirationBannerProps = {
  classes?: Partial<ClassNameMap<TrialExpirationBannerJSSClassKey>>;
  className?: string;
};

export const TrialExpirationBanner: VFC<TrialExpirationBannerProps> = ({ className, classes: extClasses }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const [daysLeftInTrial, setDaysLeftInTrial] = useState<number | null>(null);
  const [{ user }] = useUserContext();

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    reclaim.users
      .trialExpirationDate()
      .then((expMs) => {
        function updateDays(): void {
          const days = Math.ceil((expMs - new Date().getTime()) / (1000 * 60 * 60 * 24));
          setDaysLeftInTrial(days);
        }

        interval = setInterval(updateDays, 1000 * 60 * 60);
        updateDays();
      })
      .catch(console.error);

    () => clearInterval(interval);
  }, []);

  const showUpgradeBanner = useMemo(
    () => user?.sku !== ReclaimSku.Trial && typeof daysLeftInTrial === "number",
    [user?.sku, daysLeftInTrial]
  );

  return (
    <Banner className={clsx(classes.root, className)} in={showUpgradeBanner}>
      Just{" "}
      <strong>
        {daysLeftInTrial} {pluralize(daysLeftInTrial || 0, "day")} left
      </strong>{" "}
      with access to unlimited, private habits. <Link href="http://reclaim.ai/pricing">See upgrade options</Link>
    </Banner>
  );
};
