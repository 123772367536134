import { Box, Collapse, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    content: {
      backgroundColor: theme.colors.softGrey,
      height: 42,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      whiteSpace: "pre",

      "& strong": {
        display: "inline-block",
        padding: `${theme.spacing(0.1)}px ${theme.spacing(0.75)}px`,
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.colors.warning.main,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
  {
    classNamePrefix: "Banner",
  }
);

export type BannerJSSClassKey = keyof ReturnType<typeof useStyles>;

export type BannerProps = {
  classes?: Partial<ClassNameMap<BannerJSSClassKey>>;
  className?: string;
  in?: boolean;
};

export const Banner: FC<BannerProps> = ({ className, classes: extClasses, children, in: inProp }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Collapse in={inProp} className={clsx(classes.root, className)}>
      <Box className={classes.content}>
      {children}
      </Box>
    </Collapse>
  );
};
